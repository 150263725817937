// Very simple SOC estimation from open (almost) circuit voltage
export default (ocv) => {
  const points = [
    4.16, 4.06, 3.98, 3.92, 3.87, 3.82, 3.79, 3.77, 3.74, 3.68, 3.2,
  ];

  const overPoint = points.findIndex((v) => ocv >= v);
  if (overPoint < 0) return 0; // We're under 3.2, so 0 % SOC
  if (overPoint === 0) return 100;

  const over = points[overPoint];
  const next = points[overPoint - 1];
  const overSoc = 100 - overPoint * 10;

  // ocv = 4.10
  // overPoint = 1
  // over = 4.06
  // next = 4.12
  // overSoc = 90;
  // nextSoc = 100;

  // SOC = overSoc + ((ocv - over) / (next - over) * 10)

  return (overSoc + ((ocv - over) / (next - over)) * 10).toFixed(0);
};
