import React from "react";

import style from "./EnergyStats.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faBatteryThreeQuarters,
  faBolt,
  faSolarPanel,
} from "@fortawesome/free-solid-svg-icons";
import { Line } from "react-chartjs-2";
import moment from "moment";
import estimateSoc from "./estimateSoc";

import signal from "./signal.svg.jsx";

const EnergyStats = ({ samples, onClose }) => {
  if (!samples) return null;

  const values = {
    vcc: samples.map((v) => v[4]),
    vpv: samples.map((v) => v[5]),
    icc: samples.map((v) => v[6]),
    ipv: samples.map((v) => v[7]),
  };

  const live = samples.slice(-1)[0];
  const vBatt = live[4];
  const vPV = live[5];
  const iBatt = live[6];
  const iPV = live[7];

  let batteryIcon;
  if (iBatt < 0) {
    batteryIcon = faBolt;
  } else if (vBatt < 3770) {
    batteryIcon = faBatteryEmpty;
  } else if (vBatt < 3900) {
    batteryIcon = faBatteryHalf;
  } else if (vBatt < 4000) {
    batteryIcon = faBatteryThreeQuarters;
  } else {
    batteryIcon = faBatteryFull;
  }

  const labels = samples.map((v) => moment(v[0] * 1000).format("D.M. HH:mm"));

  const data = {
    labels,
    datasets: [
      {
        label: "PV mA",
        type: "line",
        fill: false,
        borderColor: "#ffdd88",
        pointBackgroundColor: "#ffdd88",
        pointHoverRadius: 5,
        pointRadius: 0,
        pointHitRadius: 10,
        yAxisID: "y-axis-2",
        data: values.ipv,
        lineTension: 0.1,
        borderWidth: 2,
      },
      {
        label: "PV mV",
        type: "line",
        fill: false,
        borderColor: "#ff00ff",
        pointBackgroundColor: "#ff00ff",
        pointHoverRadius: 5,
        pointRadius: 0,
        pointHitRadius: 10,
        yAxisID: "y-axis-1",
        data: values.vpv,
        lineTension: 0.1,
        borderWidth: 2,
      },
      {
        label: "Akku mV",
        type: "line",
        fill: false,
        borderColor: "#ff4444",
        pointBackgroundColor: "#ff4444",
        pointHoverRadius: 5,
        pointRadius: 0,
        pointHitRadius: 10,
        yAxisID: "y-axis-3",
        data: values.vcc,
        lineTension: 0.1,
        borderWidth: 2,
      },
      {
        label: "Akku mA",
        type: "line",
        fill: false,
        borderColor: "#ff9900",
        pointBackgroundColor: "#ff9900",
        pointHoverRadius: 5,
        pointRadius: 0,
        pointHitRadius: 10,
        yAxisID: "y-axis-2",
        data: values.icc,
        lineTension: 0.1,
        borderWidth: 2,
        hidden: true,
      },
    ],
  };

  const updated = moment(live[0] * 1000).fromNow();

  return (
    <div className={style.EnergyStats}>
      <button className={style.close} onClick={onClose}>
        ⊗
      </button>

      <h1>Energia</h1>
      <div className={style.rt}>
        <div className={style.rtSection}>
          <h2>
            PV
            <FontAwesomeIcon
              icon={faSolarPanel}
              style={{ marginLeft: "1rem" }}
            />
          </h2>
          <div className={style.values}>
            <div>{vPV} mV</div>
            <div>{iPV} mA</div>
          </div>
        </div>
        <div className={style.rtSection}>
          <h2>
            <div>{estimateSoc(vBatt / 1000)} %</div>
            <FontAwesomeIcon
              icon={batteryIcon}
              style={{ marginLeft: "1rem" }}
            />
          </h2>
          <div className={style.values}>
            <div>{vBatt} mV</div>
            <div>{iBatt} mA</div>
          </div>
        </div>
      </div>

      <div className={style.updated}>
        {signal} {updated}
      </div>

      <div className={style.chart}>
        <Line
          data={data}
          height={320}
          options={{
            gridLines: {
              zeroLineColor: "rgba(255,255,255,.5)",
            },
            maintainAspectRatio: false,
            hover: { animationDuration: 0 },
            legend: {
              position: "top",
              labels: { boxWidth: 10 },
            },
            scales: {
              xAxes: [
                {
                  gridLines: {
                    display: false,
                    color: "rgba(40,100,200,.1)",
                  },
                },
              ],
              yAxes: [
                {
                  type: "linear",
                  display: true,
                  position: "left",
                  id: "y-axis-1",
                  gridLines: {
                    display: true,
                    color: "rgba(40,100,200,.3)",
                  },
                  ticks: {
                    beginAtZero: false,
                  },
                  labels: {
                    show: true,
                  },
                },
                {
                  type: "linear",
                  display: true,
                  position: "right",
                  id: "y-axis-2",
                  gridLines: {
                    display: true,
                  },
                  ticks: {
                    beginAtZero: true,
                  },
                  labels: {
                    show: true,
                  },
                },
                {
                  type: "linear",
                  display: false,
                  id: "y-axis-3",
                  gridLines: {
                    display: false,
                  },
                  labels: {
                    show: true,
                  },
                },
                {
                  type: "linear",
                  display: false,
                  id: "y-axis-4",
                  gridLines: {
                    display: false,
                  },
                  ticks: {
                    beginAtZero: false,
                  },
                  labels: {
                    show: true,
                  },
                },
              ],
            },
          }}
        />
      </div>
    </div>
  );
};

export default EnergyStats;
