import config from "./config";

function getUrlForResource(resource) {
  return config.apiUrl + resource;
}

async function request(resource) {
  const response = await fetch(getUrlForResource(resource));
  return await response.json();
}

export async function getSamples(range) {
  const data = await request("samples?d=" + range);
  return data.samples;
}
