import React from "react";
import logo from "./kotirinne-gfx.svg";
import signal from "./signal.svg.jsx";
import style from "./App.module.css";
import moment from "moment";
import "moment/locale/fi";
import { getSamples } from "./api";
import classnames from "classnames";

import { Line } from "react-chartjs-2";
import { defaults } from "react-chartjs-2";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTint,
  faThermometerHalf,
  faSnowflake,
  faClock,
  faBatteryFull,
  faBolt,
  faBroadcastTower,
  faSignalStream,
  faCloud,
} from "@fortawesome/free-solid-svg-icons";
import EnergyStats from "./EnergyStats.jsx";
import estimateSoc from "./estimateSoc.js";

class QtieChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.lastUpdate != this.props.lastUpdate) {
      console.log(prevProps.lastUpdate, this.props.lastUpdate);
      if (this.chartRef) {
        this.chartRef.chartInstance.update();
      }
    }
  }

  componentDidMount() {
    defaults.global.defaultFontColor = "#456";
  }

  handleTouch = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  render() {
    if (!this.props.values) return null;

    const data = {
      labels: this.props.labels,
      datasets: [
        {
          label: "Lämpötila °C",
          type: "line",
          fill: false,
          borderColor: "rgba(200,122,42,1)",
          pointBackgroundColor: "rgba(200,122,42,1)",
          pointHoverRadius: 5,
          pointRadius: 0,
          pointHitRadius: 10,
          yAxisID: "y-axis-1",
          data: this.props.values.temperature,
          lineTension: 0.1,
        },
        {
          label: "Kosteus",
          type: "line",
          fill: false,
          borderColor: "#00596A",
          pointBackgroundColor: "#00596A",
          pointHoverRadius: 5,
          pointRadius: 0,
          pointHitRadius: 10,
          yAxisID: "y-axis-4",
          data: this.props.values.humidity,
          lineTension: 0.1,
        },
        {
          label: "Lumi cm",
          type: "bar",
          fill: false,
          backgroundColor: "#ffffff22",
          borderColor: "#ffffff22",
          hoverBackgroundColor: "#70787f",
          hoverBorderColor: "#70787f",
          yAxisID: "y-axis-2",
          data: this.props.values.snow,
        },
        {
          label: "Akku",
          type: "line",
          fill: false,
          borderColor: "rgba(80,40,40,1)",
          pointBackgroundColor: "rgba(80,40,40,1)",
          pointHoverRadius: 5,
          pointRadius: 1,
          pointHitRadius: 10,
          borderWidth: 1,
          lineTension: 0,
          yAxisID: "y-axis-3",
          data: this.props.values.battery,
          hidden: true,
        },
      ],
    };

    return (
      <div
        className={style.QtieChartWrapper}
        onTouchStart={this.handleTouch}
        onTouchMove={this.handleTouch}
      >
        <Line
          data={data}
          height={250}
          options={{
            gridLines: {
              zeroLineColor: "rgba(255,255,255,.5)",
            },
            maintainAspectRatio: false,
            hover: { animationDuration: 0 },
            legend: {
              position: "top",
              labels: { boxWidth: 10 },
            },
            scales: {
              xAxes: [
                {
                  gridLines: {
                    display: false,
                    color: "rgba(40,100,200,.1)",
                  },
                },
              ],
              yAxes: [
                {
                  type: "linear",
                  display: true,
                  position: "left",
                  id: "y-axis-1",
                  gridLines: {
                    display: true,
                    color: "rgba(40,100,200,.3)",
                    zeroLineColor: "rgba(40,140,255,.2)",
                    zeroLineWidth: 2,
                  },
                  ticks: {
                    beginAtZero: true,
                  },
                  labels: {
                    show: true,
                  },
                },
                {
                  type: "linear",
                  display: true,
                  position: "right",
                  id: "y-axis-2",
                  gridLines: {
                    display: false,
                  },
                  ticks: {
                    beginAtZero: true,
                  },
                  labels: {
                    show: true,
                  },
                },
                {
                  type: "linear",
                  display: false,
                  id: "y-axis-3",
                  gridLines: {
                    display: false,
                  },
                  labels: {
                    show: true,
                  },
                },
                {
                  type: "linear",
                  display: false,
                  id: "y-axis-4",
                  gridLines: {
                    display: false,
                  },
                  ticks: {
                    beginAtZero: false,
                  },
                  labels: {
                    show: true,
                  },
                },
              ],
            },
          }}
          ref={(ref) => {
            this.chartRef = ref;
          }}
        />
      </div>
    );
  }
}

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { samples: null, range: 3 };
  }

  componentDidMount() {
    moment.locale("fi");
    this.updateData();
    this.updateView();
  }

  updateData = async () => {
    const samples = await getSamples(this.state.range);
    this.setState({ samples });
    clearTimeout(this.timerData);
    this.timerData = setTimeout(this.updateData, 30000);
  };

  updateView = () => {
    this.setState({ viewUpdated: Date.now() });
    clearTimeout(this.timerUpdate);
    this.timerUpdate = setTimeout(this.updateView, 5000);
  };

  handleSelectRange = (range) => {
    this.setState({ range }, () => {
      this.updateData();
    });
  };

  handleCloseEnergy = () => {
    this.setState({ showEnergy: false });
  };

  handleShowEnergy = () => {
    this.setState({ showEnergy: true });
  };

  render() {
    if (!(this.state.samples && this.state.samples.length)) return null;

    const live = this.state.samples.slice(-1)[0];

    const temperature = live[1];
    const humidity = live[2];
    const snow = (Number(live[3]) / 10).toFixed(0);
    const updated = moment(live[0] * 1000).fromNow();

    const batteryVoltage = live[4];
    const batteryVoltageFormatted = estimateSoc(batteryVoltage / 1000) + " %";

    const temperatureClass = classnames({ [style.subzero]: temperature <= 0 });
    const temperatureFormatted = temperature.toFixed(1).replace(".", ",");

    const labels = this.state.samples.map((v) =>
      moment(v[0] * 1000).format("D.M. HH:mm")
    );
    const valuesTemperature = this.state.samples.map((v) => v[1]);
    const valuesHumidity = this.state.samples.map((v) => v[2]);
    const valuesSnow = this.state.samples.map((v) => (v[3] / 10).toFixed(1));
    const valuesBattery = this.state.samples.map((v) => v[4] / 1000);

    const values = {
      temperature: valuesTemperature,
      humidity: valuesHumidity,
      snow: valuesSnow,
      battery: valuesBattery,
    };

    return (
      <div className={style.Dashboard}>
        {this.state.showEnergy && (
          <EnergyStats
            samples={this.state.samples}
            onClose={this.handleCloseEnergy}
          />
        )}
        <div
          className={style.DasboardBatteryStatus}
          onClick={this.handleShowEnergy}
        >
          <FontAwesomeIcon
            icon={faBatteryFull}
            className={style.DashboardBatteryStatusIcon}
          />
          {batteryVoltageFormatted}
        </div>

        <img src={logo} className={style.AppLogo} alt="logo" />

        <div className={style.DashboardGauges}>
          <div className={style.DashboardItem}>
            <FontAwesomeIcon
              icon={faThermometerHalf}
              className={style.DashboardItemIcon}
            />
            <h1 className={temperatureClass}>{temperatureFormatted} °C</h1>
          </div>
          <div className={style.DashboardItem}>
            <FontAwesomeIcon
              icon={faTint}
              className={style.DashboardItemIcon}
            />
            <h1>{humidity} %</h1>
          </div>
          <div className={style.DashboardItem}>
            <FontAwesomeIcon
              icon={faSnowflake}
              className={style.DashboardItemIcon}
            />
            <h1>{snow} cm</h1>
          </div>
        </div>

        <h2 className={style.DashboardInfo}>
          {/* <FontAwesomeIcon
            icon={faBroadcastTower}
            className={style.DashboardInfoIcon}
          /> */}
          {signal} {updated}
        </h2>

        <QtieChart
          labels={labels}
          values={values}
          lastUpdate={live[0] + valuesTemperature.length}
        />

        <div className={style.DashboardChartRange}>
          <div
            className={
              this.state.range == 1 && style.DashboardChartRangeSelected
            }
            onClick={() => {
              this.handleSelectRange(1);
            }}
          >
            24 h
          </div>
          <div
            className={
              this.state.range == 3 && style.DashboardChartRangeSelected
            }
            onClick={() => {
              this.handleSelectRange(3);
            }}
          >
            3 pv
          </div>
          <div
            className={
              this.state.range == 31 && style.DashboardChartRangeSelected
            }
            onClick={() => {
              this.handleSelectRange(31);
            }}
          >
            1 kk
          </div>
          <div
            className={
              this.state.range == 186 && style.DashboardChartRangeSelected
            }
            onClick={() => {
              this.handleSelectRange(186);
            }}
          >
            6 kk
          </div>
          <div
            className={
              this.state.range == 365 && style.DashboardChartRangeSelected
            }
            onClick={() => {
              this.handleSelectRange(365);
            }}
          >
            12 kk
          </div>
          <div
            className={
              this.state.range == 730 && style.DashboardChartRangeSelected
            }
            onClick={() => {
              this.handleSelectRange(730);
            }}
          >
            24 kk
          </div>
        </div>
      </div>
    );
  }
}

class App extends React.Component {
  render() {
    return (
      <div className={style.App}>
        <div className={style.AppContent}>
          <Dashboard />
        </div>
      </div>
    );
  }
}

export default App;
